<template>
  <el-container class="shopList ">
    <el-header class="box add">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
       <el-breadcrumb-item>账号更新</el-breadcrumb-item>
    </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
     <el-form :inline="true"  status-icon ref="form3"  :model="form3"  class="demo-ruleForm">
     <el-row class="box  f14">
          <el-col :span="24" class="tl" >
          <div class="p10 error_text f13 mb20"><p>您正在进行敏感操作，继续操作请验证您之前的身份</p>
              <p>更换号码后，您将无法通过原手机号码加密码登录</p>
          </div>
        </el-col>
          <el-col :span="11" class="tl clear" >
        <el-form-item  label="登录账号：" size="medium" class="w" label-width="28%"  >
           <el-input placeholder="登录账号" maxlength='30' disabled clearable v-model="user_loginname" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="clear" >
        <el-form-item  label="原密码：" size="medium" class="w" label-width="28%"  >
           <el-input type="text" placeholder="原密码" minlength="4" maxlength='15' clearable v-model="loginpass" class="input-with-select"  @input="handelKeyup(loginpass)">
            </el-input>
        </el-form-item>
        </el-col>
         <div v-if="showDiv">
            <el-col :span="11" class="tl clear" >
           <el-form-item  label="滑块验证：" size="medium" class="w" label-width="28%" >
           <JcRange status="ruleForm.status" :successFun="onMpanelSuccess" :errorFun="onMpanelError"></JcRange>
        </el-form-item>
        </el-col>
          <el-col :span="11" class="tl clear" >
        <el-form-item  label="新手机号：" size="medium" class="w" label-width="28%"  prop="user_loginname" :rules="[{required: true,message: '请输入新手机号', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
           <el-input placeholder="新手机号" maxlength='30'  clearable v-model="form3.user_loginname" class="input-with-select">
               <el-button slot="append" icon="el-icon-mobile-phone" @click="takeNum('form3')" type="primary" size="medium" :disabled="disabled=!show" >  
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
            </el-button>
            </el-input>
        </el-form-item>
        </el-col>
        <!-- <el-col :span="4" class="" >
           <el-button icon="el-icon-mobile-phone" @click="takeNum('form3')" type="primary" size="medium" :disabled="disabled=!show" >  
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
            </el-button>
        </el-col> -->
           <el-col :span="11" class="tl clear" >
           <el-form-item  label="验证码：" size="medium" class="w" label-width="28%"  prop="mess_code" :rules="[{required: true,message: '请输入验证码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
           <el-input placeholder="验证码" maxlength='30' clearable v-model="form3.mess_code" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        
        
         
        <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm2('form3')">提交</el-button>
        </el-col>
         </div>
         <el-col v-else :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm">验证</el-button>
        </el-col>
     </el-row>
        </el-form>  
</el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,aa,objKeySort } from '../../../util/util'
import {imageUrl} from '../../../config/index'
import JcRange from "@/components/JcRange.vue";
import { mapState } from 'vuex'
import md5 from 'js-md5';
export default {
      components: {
		JcRange
	},
  data () {
      var checkStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请拖动滑块完成验证"));
      } else {
        callback();
      }
    };
    return {
      user:'',
      timer: '',
      loading:false,
      tableData:[],
      show: true,
      showDiv:false,
      count: '',
      user_loginname:'',
      user_loginpass:'',
      loginpass:'',
      form3:{
        mess_code:'',
        query_param:'',
        user_loginname:''
      },
      status1:'',
      verify:false,
      ruleForm:{
        status: [{ validator: checkStatus, trigger: "change" }]
      }
    }
  },
  watch: {
  },
   created () {
     var that=this;
      that.user = local.get('user');
    that.getData()
  },
  methods: {
    handelKeyup(val){
     this.user_loginpass+=val.substr(val.length-1,1);
     this.loginpass=val.replace(/./g,'●')
    },
      onMpanelSuccess(){
      this.form3.query_param='2fb1c5cf58867b5bbc9a1b145a86f3a0';
    },
    onMpanelError(){
       this.form3.query_param='';
    },
       getData () {
          var that=this;
          axios.get('/pc/user/single').then((v) => {
        that.user=v.data.user;
        that.user_loginname=that.user.user_mobilephone
      })
    },
    submitForm(){
         if(this.user_loginname==''){
            this.$message.error("请输入登录账号");
            return false;
          }
            if(this.user_loginpass==''){
            this.$message.error("请输入密码");
            return false;
          }
          this.checkPassword(this.user_loginpass)
    },
      submitForm2(form3){
         this.$refs[form3].validate((valid) => {
        if (valid) {           
            axios.put('/pc/user/loginname/update', this.form3).then((v) => {
            })
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      //验证账号密码是否有效
      checkPassword(val){
        axios.get('/pc/user/verify',{user_loginname:this.user_loginname,user_loginpass:md5(val)}).then((v) => {
            if(v.code!=200){
              this.user_loginpass='';
            }else{
              this.showDiv=true;
            }
        // this.verify=true
      })
      },
      //获取验证码
    takeNum(form3){
        // if(this.user_loginpass==''){
        //     this.$message.error("请输入密码");
        //     return false;
        //   }
          // if(!this.verify){
          //   this.$message.error("账号密码验证不正确");
          //   return false;
          // }
       if(this.form3.query_param==''){
            this.$message.error("滑块验证不成功");
            return false;
          }
       let user_mobilephone=this.user_loginname+"";
       let myThis=this;
        this.form3.messcode='';
        this.messget(user_mobilephone);
    },
    messget(loginname){      
      axios.get('/pc/sms/send',{user_mobilephone:loginname,mess_type:'1'}).then((v) => {   
        this.send(v.data.mess_code);               
      }).catch()
    },
    send(messcode){
      this.form3.mess_code= messcode;
      let TIME_COUNT = 60;
      if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
    },
}
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .active {
       background: #2EC68A;
    border-color: #2EC68A;
    color: #FFFFFF;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
}

</style>
